import React, { useEffect } from "react"
import Modal from 'react-modal'
import PropTypes from "prop-types"

import ImageSuccess from "../images/group-110.svg"
import ImageClose from "../images/close_36px.svg"

const FormSubmitModal = ({isOpen, close}) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="modal-overlay"
            className="modal"
        >
            <button className="modal__close-button" onClick={close}>
                <img src={ImageClose} alt="close" />
            </button>
            
            <p className="modal__title my-3">¡GRACIAS!</p>
            <img className="mt-3"src={ImageSuccess} alt="success" />
            <p className="modal__message">
            Hemos recibido tu mensaje, pronto nos pondremos en contacto contigo.
            </p>
        </Modal>
    )
}

FormSubmitModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

export default FormSubmitModal