import React, { useEffect } from "react"
import Modal from 'react-modal'
import PropTypes from "prop-types"

import ImageError from "../images/red-cross.svg"
import ImageClose from "../images/close_36px.svg"

const FormSubmitModal = ({isOpen, close}) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="modal-overlay"
            className="modal"
        >
            <button className="modal__close-button" onClick={close}>
                <img src={ImageClose} alt="close" />
            </button>
           
            <p className="modal__title my-3">¡Lo sentimos!</p>
            <img className="mt-3" src={ImageError} alt="wrong" />
            <p className="modal__message">
                Ocurrió un error desconocido.
                Por favor, intenta de nuevo.
            </p>
        </Modal>
    )
}

FormSubmitModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

export default FormSubmitModal