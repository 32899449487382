import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import Fade from 'react-reveal/Fade'

const CarouselProps = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const items = props.items;

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.id}
                >
                <div className="carousel-container position-relative">
                    <img src={item.src} alt={item.altText} />
                    <Fade duration={1500} left delay={500}>
                        <div className="carousel-container__caption position-absolute d-flex">
                            <div className="caption-text">
                                <h1 className="font-weight-normal text-white texto__title">{item.captionTitle}</h1>
                                <h1 className="font-weight-bold text-white texto__bold">{item.captionTitleBold}</h1>
                                <p className="text-white mb-0 texto__info">{item.captionText}</p>
                            </div>
                        </div>
                    </Fade>
                </div>
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            pause='false'     
            ride='carousel'   
            >
            <CarouselIndicators 
                items={items} 
                activeIndex={activeIndex}
                onClickHandler={goToIndex} />
            {slides}
        </Carousel>
    );
}

export default CarouselProps;