

import React, { useState } from 'react'; 
import { Carousel, CarouselItem,  CarouselIndicators, CarouselCaption } from 'reactstrap';
import ArrowBack from '../../images/assets/left-arrow.svg';
import ArrowForward from '../../images/assets/right-arrow.svg';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
const ImageGallery = ({  imageList, title, text, acordion,acordion2, textPosition, buttonPosition  }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const items = imageList;
     const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState('VER MÁS');
    const onEntered = () => setStatus('VER MENOS');
    const onExited = () => setStatus('VER MÁS');
    const toggle = () => setCollapse(!collapse);

const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
}

const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
}

const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
}
 if (!items.length) {
        return <div></div>
    }

 const slides = items.map((item, index) => {

   
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                //key={item.id}
                >
               
                    <div className={textPosition === 'left' ? 'd-flex simple-trans-main' : 'd-none'}>
       
                      <img src={item} className={textPosition === 'left' ? 'd-flex' : 'd-none'} />
                    </div>
                    <div className={textPosition === 'left' ? 'd-none' : 'd-flex simple-trans-main'} >
       
                       <img src={item} width="100%"    />
            
                    </div>
             
            </CarouselItem>
        );
    });

return (
    <div className={textPosition === 'left' ? 'image-gallery' : 'image-gallery direction-reverse'} >
        <div className={ textPosition === 'left' ? 'margin-left image-gallery__static d-flex align-items-center my-4 my-lg-1' : 'margin-right image-gallery__static d-flex align-items-center my-4 my-lg-1'}>
                <div className={ buttonPosition === 'top' ? 'image-gallery__button-group top my-lg-5 py-lg-3' : 'image-gallery__button-group bottom  my-lg-5 py-lg-3'}>
                    <button className="image-gallery__button" onClick={previous}>
                        <img src={ArrowBack} alt="back" />
                    </button>
                    <div className="d-flex align-items-start px-2">
                        <span className="">
                           {activeIndex+1}
                        </span>
                        <span className="px-2">
                            /
                        </span>
                        <span className="">
                            {items.length}
                        </span>
                    </div>
                    
                    <button className="image-gallery__button" onClick={next}>
                        <img src={ArrowForward} alt="forward" />
                    </button>
                </div>
                <div className="image-gallery__text mx-md-5">
                    <h1 className="mb-4 font-weight-bold text-dark-gray tituloGallery">
                        {title}
                    </h1>
                    <p className="text-gray font-weight-light textoGallery">
                        {text}
                    </p>
             
                    
                <Collapse  isOpen={collapse}
                  onEntered={onEntered}
                  onExited={onExited}>
                  <Card>
                   <CardBody>
                   <p  className="text-gray font-weight-light textoGallery my-3 ">
                        {acordion}<br/>
                       <p className="mt-3"> {acordion2}</p>

                    </p>
                  </CardBody>
                 </Card>
               </Collapse>
             <Button  onClick={toggle} style={{ marginTop: '0.5rem' }}>{status}</Button>
                </div>
            </div>
       <div className="caouGallery"  >
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
             pause='false'     // *********
            ride='carousel'
            >
            <CarouselIndicators 
                
                items={items} 
                activeIndex={activeIndex}
                onClickHandler={goToIndex} />
            {slides}
        </Carousel>
        </div>
      </div>
    );





}


export default ImageGallery












































