import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import {useSprings,animated} from 'react-spring'

import ArrowBack from '../../images/assets/left-arrow.svg';
import ArrowForward from '../../images/assets/right-arrow.svg';

import Galeria from './galeria';

const TabSlider = ({ proyectos }) => {
  
    const [active, setActive] = useState(0)
    const [indexs, setIndexs] = useState([0,1,2,3,4])
    const [open, setOpen] = useState([0,0,0,0,0])
    const onClickBack = () => {
        switch(active){
            case 0:
                setIndexs([3,4,0,1,2])
                break;
            case 1:
                setIndexs([4,0,1,2,3])
                break;
            case 2:
                setIndexs([1,2,3,4,0])
                break;
            case 3:
                setIndexs([2,3,4,0,1])
                break;

            case 4:
                setIndexs([3,4,0,1,2])
                break;
            
            default:
                setIndexs([0,1,2,3,4])
                break;
        }
        setActive(state => state === 0 ? proyectos.length - 1 : state - 1)
    }
    const onClickForward = () => {
        switch(active){
            case 0:
                setIndexs([1,2,3,4,0])
                break;
            case 1:
                setIndexs([2,3,4,0,1])
                break;
            case 2:
                setIndexs([3,4,0,1,2])
                break;
            case 3:
                setIndexs([4,0,1,2,3])
                break;
            default:
                setIndexs([0,1,2,3,4])
                break;
        }
        setActive(state => state === proyectos.length - 1 ? 0 : state + 1)
    }
    
    const openInfo = (  value  ) => {
        switch(value){
            case 0:
                setOpen([1,0,0,0,0])
                break;
            case 1:
                setOpen([0,1,0,0,0])
                break;
            case 2:
                setOpen([0,0,1,0,0])
                break;
            case 3:
                setOpen([0,0,0,1,0])
                break;
            case 4:
                setOpen([0,0,0,0,1])
                    break;
            default:
                setOpen([0,0,0,0,0])
                break;
        }
    }

    return (
        <div className="tab-slider">
            <div className="tab-slider__title d-md-flex mb-5 py-md-1 mb-md-1">
                {
                    indexs.map(function (x, key){
                        return (
                            <>
                                <div className={key === 0 ? 'tab active' : 'tab no-active'}>
                                    { key === 0 && <img className="d-none d-md-block" src={ArrowBack} alt="back" onClick={onClickBack} /> }
                                    <span className="test" >
                                    
                                        {proyectos[x].title}
                                    </span>
                                    { key === 0 && <img className="d-none d-md-block" src={ArrowForward} alt="forward" onClick={onClickForward} /> }
                                    {
                                        !open[key] ?
                                            <button className="d-md-none" onClick={() => openInfo(x)}>
                                                +
                                            </button>
                                        :
                                            <button className="d-md-none" onClick={() => openInfo('-')}>
                                                -
                                            </button>
                                    }   
                                </div>
                                {
                                    open[key] ?
                                        <div className="tab-slider__text d-md-none">
                                            <div>
                                                <p className="mt-2">
                                                {
                                                    proyectos[key].text
                                                  
                                                }
                                                </p>
                                                <p className="mt-2">
                                                {
                                                    proyectos[key].text2
                                                  
                                                }
                                                </p>
                                                <p className="mt-2">
                                                {
                                                    proyectos[key].text3
                                                  
                                                }
                                                </p>
                                                <p className="mt-2">
                                                {
                                                    proyectos[key].text4
                                                  
                                                }
                                                </p>
                                                
                                            </div>
                                        </div>
                                    :
                                        <>
                                        </>
                                }    
                                {
                                    open[key] ?
                                        <div className="tab-slider__galeria d-md-none">
                                            <Galeria { ... proyectos[key]} />
                                        </div>
                                    :
                                        <></>
                                }
                            </>
                        )
                    })
                }
            </div>
            <div className="tab-slider__text d-none d-md-block">
            <div>
                                                <p className="mt-1">
                                                {
                                                    proyectos[active].text
                                                  
                                                }
                                                </p>
                                                <p className="mt-1">
                                                {
                                                    proyectos[active].text2
                                                  
                                                }
                                                </p>
                                                <p className="mt-1">
                                                {
                                                    proyectos[active].text3
                                                  
                                                }
                                                </p>
                                                <p className="mt-1">
                                                {
                                                    proyectos[active].text4
                                                  
                                                }
                                                </p>
                                                <p className="mt-1">
                                                {
                                                    proyectos[active].text5
                                                  
                                                }
                                                </p>
                                            </div>
            </div>
            <div className="tab-slider__galeria d-none d-md-flex">
                <Galeria { ... proyectos[active]} />
            </div>
        </div>
    )
}

TabSlider.propTypes = {
    proyectos: PropTypes.object.isRequired
}

export default TabSlider;