import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/sliders/carousel";
import ImageDoubleGallery from '../components/sliders/imageDoubleGallery';
import ImageGallery from '../components/sliders/imageGallery';
import {IMAGES_DOUBLE_GALLERY_ARRAY, Carousel_Items, ARQUITECTURA, ARQUITECTURA_DE_INTERIORES, CONSTRUCCION, NUESTROS_PROYECTOS } from '../constants';
import BackgroundUs from '../images/assets/background-us.jpg';
import BackgroundServicios from '../images/assets/bg-servicios.jpg';
import R from '../images/assets/r.svg';
import Contacto from '../images/assets/contacto.jpg';
import Form from '../components/form';
import TabSlider from '../components/sliders/tabSlider';


    


const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Carousel items={Carousel_Items}/>
        <div className="nosotros my-lg-5 p" id="nosotros">
            <ImageDoubleGallery {... IMAGES_DOUBLE_GALLERY_ARRAY }/>
            <div className="nosotros__background position-relative">
                <img className="w-100 img-nosotros d-none d-md-block" src={BackgroundUs} alt="bakcgorundus" />
                <div className="nosotros__text position-absolute d-flex align-items-center justify-content-center">
                    <div>
                        <h2 className="text-white mb-3  p-3 p-md-1 ">
                            NOSOTROS
                        </h2>
                        <p className="mb-0 text-white p-3 p-md-1 mb-5 mb-md-2 nosotros__p">
                           
                            Somos una firma de arquitectos que redefinen los espacios. Buscamos constantemente materializar las ideas de nuestros clientes a través de la innovación en el diseño y la arquitectura.
                        </p>
                    </div>
                </div>
            </div>
            
            
        </div>
        
        <div className="servicios position-relative d-flex" id="servicios">
            <img src={R} alt="Textura R" className="servicios__textura"/>
            <div className="mt-4 d-flex flex-column justify-content-center servicios__text  my-lg-5">
                <h1 className="mb-4  text-dark-gray title-mobile text-center text-md-left">
                    NUESTROS SERVICIOS
                </h1>
                <p className="text-gray nuestroTexto">
                    Nuestro enfoque es generar arquitectura de calidad. Creamos espacios de interiores y exteriores que dan como resultado proyectos creativos que se llevan a construcción, siempre considerando el contexto, al cliente y el presupuesto. 
                </p>
            </div>
            <div className="servicios__line py-5 w-100">
                <div className="mt-5">
                </div>
            </div>
        </div>

        <div className="arquitectura position-relative  my-lg-5" id="arquitectura">
            <ImageGallery { ... ARQUITECTURA } />
            <img className="arquitectura__image position-absolute" src={BackgroundServicios} alt="Background Servicios"/>
        </div>
        <div className="arquitectura-interiores mx--md-5  my-lg-5" id="arquitectura-interiores">
            <ImageGallery { ... ARQUITECTURA_DE_INTERIORES } />
        </div>
        <div className="construccion mx-md-5 my-lg-5" id="construccion">
            <ImageGallery { ... CONSTRUCCION } />
        </div>
        <section id="nuestrosproyectos" className="my-lg-5 py-lg-3">
        <div  className="mx-md-5 proyectos my-md-5" >
            <div className="proyectos__title pl-md-5 ml-md-5 mb-5">
                <div className="d-flex">
                    <h1 className="text-dark-gray p-4 p-md-0 mb-0 title-mobile">
                        NUESTROS PROYECTOS
                    </h1>
                    <div className="proyectos__decorator d-md-flex d-none align-items-center">
                        <div />
                    </div>
                </div>
            </div>
            <div className="proyectos__component">
                <TabSlider { ... NUESTROS_PROYECTOS} />
            </div>
        </div>
    </section>
        <div className="mx-md-5 contacto mx-0 row mt-md-5" >
            <div className="col-md-5 px-3 position-relative d-none d-md-flex">
                <img src={Contacto} alt="contacto" className="contacto__image w-100 mx-5" />
            </div>
            <div className="col-md-2" />
            <div className="col-md-5 formulario px-md-5 d-flex align-items-center h-100" id="contacto">
                <Form />
            </div>
        </div>
        
        <div className="ubicacion">
            <div className="ubicacion__title pl-md-5 ml-md-5 mb-5">
                <div className="d-flex">
                    <h1 className="text-dark-gray mb-0 ubicacion-title">
                        NUESTRA UBICACIÓN
                    </h1>
                    <div className="ubicacion__decorator d-md-flex d-none align-items-center">
                        <div />
                    </div>
                </div>
            </div>
            <div className="ubicacion__text pl-md-5 ml-md-5 mb-md-5">
                <p className="text-gray">
                    Leibnitz 14, interior 605, col. Anzures, del. Miguel Hidalgo, C.P: 11590, CDMX
                </p>
            </div>
            <div className="ubicacion__map px-md-5 mx-md-5">
                <iframe src="https://snazzymaps.com/embed/220191" style={{width:'100%', height:'100%', border:'none'}}></iframe>
            </div>
        </div>
    </Layout>
)

export default IndexPage
