
import React, { useState } from 'react'; 
import { Carousel, CarouselItem,  CarouselIndicators, CarouselCaption } from 'reactstrap';
import ArrowBack from '../../images/assets/left-arrow.svg';
import ArrowForward from '../../images/assets/right-arrow.svg';

const Galeria = ({ imageList }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const items = imageList;

const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
}

const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
}

const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
}
function images(images,index){

       
       if(images.length==(index+1)){
            return images[1];
       }
       return images[index+1];
}

 if (!items.length) {
        return <div></div>
    }


 const slides = items.map((item, index) => {

   
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                //key={item.id}
                >
             <div class="galeria__image d-flex">
                <div className="galeria-trans-main">
                    <img src={item} width="100%" height="100%"/>
                </div>

                <div className="galeria-trans-next" >
                    <img src={images(items,index)}  hspace="200" width="100%" height="100%"/>
                </div>
             </div>
            </CarouselItem>
        );
    });

return (
    <div className="galeria">
        <div className="galeria__button-group" align="left" width="50%">
                <button className="galeria__button mr-3" onClick={previous}>
                    <img src={ArrowBack} alt="back" />
                </button>
                <button className="galeria__button" onClick={next}>
                    <img src={ArrowForward} alt="forward" />
                </button>
        </div>
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
             pause='false'     // *********
            ride='carousel'
            >
            <CarouselIndicators 
                
                items={items} 
                activeIndex={activeIndex}
                onClickHandler={goToIndex} />
            {slides}
        </Carousel>
      </div>
    );





}


export default Galeria