import React from "react"
import PropTypes from "prop-types"

const Input = ({label, type, value, onChange, isRequired = false, inputClass, errorMessage }) => (
    <div className="input-wrapper px-4 px-md-0">
        <input
            type={type || 'text'}
            className={"input " + inputClass}
            required={isRequired}
            onChange={({target}) => onChange(target.value)}
            placeholder={label}
        />
        <label className="input-label-error">{errorMessage}</label>
    </div>
)

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    isRequired: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    inputClass: PropTypes.string,
    errorMessage: PropTypes.string,
}

export default Input