import React, { useState } from "react"
import PropTypes from "prop-types"

import Input from "../components/input"
import Button from "../components/button"
import axios from 'axios';
import Fade from 'react-reveal/Fade'
import FormSubmitModal from "./formSubmitModal"
import FormWrongSubmitModal from "./formWrongSubmitModal"

const EMPTY_FORM = {
    name: '',
    phone: '',
    email: '',
}

const EMPTY_ERRORS = {
    name: '',
    phone: '',
    email: '',
}
const WRONG_RESPONSE = {
    type: 'error',
    status: '500',
    message: '',
}

const Form = () => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [form, setForm ] = useState(EMPTY_FORM)
    const [formError, setFormError ] = useState(EMPTY_ERRORS)
    const [axiosResponse, setAxiosResponse] = useState({WRONG_RESPONSE})
    const onChangeForm = (item) => {
        const regexp = new RegExp(`^-?[0-9]*$`);
        if(item.hasOwnProperty('name')){
            if(item.name.length === 0){
                setFormError({
                    ...formError,
                    ...{name: 'Este campo es requerido.'},
                })
            }else{
                setFormError({
                    ...formError,
                    ...{name: ''},
                })
            }
        }
        if(item.hasOwnProperty('email')){
            if(item.email.length === 0){
                setFormError({
                    ...formError,
                    ...{email: 'Este campo es requerido.'},
                })
            }else{
                if(!item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    setFormError({
                        ...formError,
                        ...{email: 'Ingresa un correo electrónico válido.'},
                    })
                }else{
                    setFormError({
                        ...formError,
                        ...{email: ''},
                    })
                }
            }
        }
        if(item.hasOwnProperty('phone')){
            if(item.phone.length === 0){
                setFormError({
                    ...formError,
                    ...{phone: 'Este campo es requerido.'},
                })
            }else{
                if(!regexp.test(item.phone)){
                    setFormError({
                        ...formError,
                        ...{phone: 'Ingresa un número telefónico válido.'},
                    })
                }else{
                    if(item.phone.length < 8 || item.phone.length > 12){
                        setFormError({
                            ...formError,
                            ...{phone: 'Ingresa un número telefónico válido.'},
                        })
                    }else{
                        setFormError({
                            ...formError,
                            ...{phone: ''},
                        })
                    }
                }
            }
        }
        setForm({
            ...form,
            ...item,
        })
        console.log('IS FORM VALID', isFormValid());
    }

    const isFormValid = () => {
        const { name, phone, email } = form
        let areInputsValid = false

        if (name !== '' && phone !== '' && email !== '') {
            if(formError.name.length === 0 && formError.phone.length === 0 && formError.email.length === 0 )
                areInputsValid = true;
        }
        return areInputsValid;
    }

    const submitForm = (e) => {
        e.preventDefault()
        axios.post('https://hooks.zapier.com/hooks/catch/2364137/om1v52h/', { form: form },{headers:{'Content-Type': 'application/x-www-form-urlencoded',}})
        .then(
            (response) => {
                setAxiosResponse({
                    type: 'success',
                    status: '200',
                    message: 'El mensaje fue enviado con éxito.',
                })
                console.log(response, 'response')
                setIsModalOpen(true)
            },
            (error) => {
                setAxiosResponse({
                    type: 'error',
                    status: '500',
                    message: 'Server error.',
                })
                console.log(error, 'error')
                setIsModalOpen(true)
            },
        ).catch(
            (error) => {
                setAxiosResponse({
                    type: 'error',
                    status: '500',
                    message: 'Server error.',
                })
                console.log(error, 'error')
                setIsModalOpen(true)
            },
        );
    }

    const closeModal = () => {
        setForm(EMPTY_FORM)
        setIsModalOpen(false)
    }
    return (
        <React.Fragment>
            <Fade duration={3000} cascade>
                <form className="contact-form mt-4 mt-lg-0">
                    <h1 className=" text-white contact-form__title mb-md-4 px-3 mx-3 ">
                        ME INTERESA
                    </h1>
                    <p className="contact-form__text text-white my-3 px-3 mx-3 px-md-0 mx-md-2">
                    Déjanos tus datos y nos pondremos en contacto.
                    </p>
                    <div className="my-5"> 
                    <Input
                        label='Nombre completo'
                        isRequired={true}
                        value={form.name}
                        onChange={(value) => onChangeForm({ name: value})}
                        inputClass={formError.name.length > 0 ? 'error mt-4': 'mt-4'}
                        errorMessage={formError.name.length > 0 && formError.name}
                        />
                    </div>
                    <div className="my-4"> 
                    <Input
                        label='Correo electrónico'
                        type='email'
                        isRequired={true}
                        value={form.email}
                        onChange={(value) => onChangeForm({ email: value})}
                        inputClass={formError.email.length > 0 ? 'error mt-4': 'mt-4'}
                        errorMessage={formError.email.length > 0 && formError.email}
                        />
                    </div>

                    <div className="my-4"> 
                    <Input
                        label='Teléfono'
                        isRequired={true}
                        value={form.phone}
                        onChange={(value) => onChangeForm({ phone: value})}
                        inputClass={formError.phone.length > 0 ? 'error mt-4': 'mt-4'}
                        errorMessage={formError.phone.length > 0 && formError.phone}
                        />
                    </div>

                    <div className="mt-4">
                        <Button
                            type='secondary'
                            onClick={e => submitForm(e)}
                            disabled={!isFormValid()}
                            buttonClass={isFormValid() ? 'button-valid': 'button-invalid'}
                            >
                            ENVIAR
                        </Button>
                    </div>
                </form>
            </Fade>
            { axiosResponse.type === 'error'
                ? <FormWrongSubmitModal isOpen={isModalOpen} close={closeModal} />
                : <FormSubmitModal isOpen={isModalOpen} close={closeModal} />}
        </React.Fragment>
    )
}

export default Form