// Images

//Logos
// import LogoTrstudio from "../images/logos/trstudio.svg";

//Carousel
import Carousel1 from "../images/carousel/1.jpg";

//Galeria doble
import imageDoble1 from '../images/galeria-doble/1.jpg';
import imageDoble2 from '../images/galeria-doble/2.jpg';
import imageDoble3 from '../images/galeria-doble/1.jpg';
import imageDoble4 from '../images/galeria-doble/2.jpg';

//Galeria simple
//Arquitectura
import Arquitectura1 from '../images/galeria/arquitectura/1.jpg';
import Arquitectura2 from '../images/galeria/arquitectura/1.jpg';
import Arquitectura3 from '../images/galeria/arquitectura/1.jpg';
import Arquitectura4 from '../images/galeria/arquitectura/1.jpg';
//Arquitectura de interiores
import ArquitecturaInteriores1 from '../images/galeria/arquitectura-interiores/1.jpg'
import ArquitecturaInteriores2 from '../images/galeria/arquitectura-interiores/1.jpg'
import ArquitecturaInteriores3 from '../images/galeria/arquitectura-interiores/1.jpg'
import ArquitecturaInteriores4 from '../images/galeria/arquitectura-interiores/1.jpg'
//Construccion
import Construccion1 from '../images/galeria/construccion/1.jpg'
import Construccion2 from '../images/galeria/construccion/1.jpg'
import Construccion3 from '../images/galeria/construccion/1.jpg'
import Construccion4 from '../images/galeria/construccion/1.jpg'

//Proyectos
//Vivienda Colectiva
import ViviendaColectiva1 from '../images/proyectos/vivienda-colectiva/A01.jpg'
import ViviendaColectiva2 from '../images/proyectos/vivienda-colectiva/A02.jpg'
import ViviendaColectiva3 from '../images/proyectos/vivienda-colectiva/A03.jpg'
import ViviendaColectiva4 from '../images/proyectos/vivienda-colectiva/A04.jpg'
import ViviendaColectiva5 from '../images/proyectos/vivienda-colectiva/A05.jpg'
import ViviendaColectiva6 from '../images/proyectos/vivienda-colectiva/A06.jpg'
//General
import General1 from '../images/proyectos/general/1.jpg'
import General2 from '../images/proyectos/general/2.jpg'
import General3 from '../images/proyectos/general/4.jpg'
import General4 from '../images/proyectos/general/5.jpg'
import General5 from '../images/proyectos/general/6.png'
import General6 from '../images/proyectos/general/9.jpg'
import General7 from '../images/proyectos/general/11.png'
import General8 from '../images/proyectos/general/12.jpg'
import General9 from '../images/proyectos/general/13.jpg'
import General10 from '../images/proyectos/general/16.jpg'
//Oficinas D2
import Oficinas1 from '../images/proyectos/oficinas-d2/D01.jpg'
import Oficinas2 from '../images/proyectos/oficinas-d2/D02.jpg'
import Oficinas3 from '../images/proyectos/oficinas-d2/D03.jpg'
import Oficinas4 from '../images/proyectos/oficinas-d2/D04.jpg'
import Oficinas5 from '../images/proyectos/oficinas-d2/D05.jpg'
import Oficinas6 from '../images/proyectos/oficinas-d2/D06.jpg'
//Flamencos 115
import Flamencos1 from '../images/proyectos/flamecos-115/F01.jpg'
import Flamencos2 from '../images/proyectos/flamecos-115/F02.jpg'
import Flamencos3 from '../images/proyectos/flamecos-115/F03.jpg'
import Flamencos4 from '../images/proyectos/flamecos-115/F04.jpg'
import Flamencos5 from '../images/proyectos/flamecos-115/F05.jpg'
import Flamencos6 from '../images/proyectos/flamecos-115/F06.jpg'
import Flamencos7 from '../images/proyectos/flamecos-115/F07.jpg'
import Flamencos8 from '../images/proyectos/flamecos-115/F08.jpg'
//Casa María
import CasaMaria1 from '../images/proyectos/casa-maria/01.jpg'
import CasaMaria2 from '../images/proyectos/casa-maria/02.jpg'
import CasaMaria3 from '../images/proyectos/casa-maria/03.jpg'
import CasaMaria4 from '../images/proyectos/casa-maria/04.jpg'
import CasaMaria5 from '../images/proyectos/casa-maria/05.jpg'
import CasaMaria6 from '../images/proyectos/casa-maria/06.jpg'
import CasaMaria7 from '../images/proyectos/casa-maria/07.jpg'
import CasaMaria8 from '../images/proyectos/casa-maria/08.jpg'
import CasaMaria9 from '../images/proyectos/casa-maria/09.jpg'
import CasaMaria10 from '../images/proyectos/casa-maria/10.jpg'
import CasaMaria11 from '../images/proyectos/casa-maria/11.jpg'
import CasaMaria12 from '../images/proyectos/casa-maria/12.jpg'
import CasaMaria13 from '../images/proyectos/casa-maria/13.jpg'
import CasaMaria14 from '../images/proyectos/casa-maria/14.jpg'
import CasaMaria15 from '../images/proyectos/casa-maria/15.jpg'
import CasaMaria16 from '../images/proyectos/casa-maria/16.jpg'
import CasaMaria17 from '../images/proyectos/casa-maria/17.jpg'
// Constants
export const Carousel_Items = [
    {
        id: 1,
        src: Carousel1,
        altText: 'Carousel 1',
        captionTitle: 'MATERIALIZAMOS',
        captionTitleBold: 'IDEAS',
        captionText: ''
    },
    {
        id: 2,
        src: Carousel1,
        altText: 'Carousel 1',
        captionTitle: 'DISEÑO',
        captionTitleBold: 'E INOVACIÓN',
        captionText: ''
    },
    {
        id: 3,
        src: Carousel1,
        altText: 'Carousel 1',
        captionTitle: 'CREATIVIDAD',
        captionTitleBold: 'Y COMPROMISO',
        captionText: ''
    },
]

export const IMAGES_DOUBLE_GALLERY_ARRAY = {
    imageList:
    [
        imageDoble1,
        imageDoble2,
        imageDoble3,
        imageDoble4
    ]
}

export const ARQUITECTURA = {
    imageList: [
        Arquitectura1,
        Arquitectura2,
        Arquitectura3,
        Arquitectura4
    ],
    title: 'ARQUITECTURA',
    text: 'Proyectar la arquitectura es un proceso. Comenzando con la visión, la cual es crear un espacio público o privado, interior o exterior, en donde el diseño es la prioridad. En TR Studio no nos limitamos a un solo estilo de arquitectura, nos gusta combinar el pasado y el presente para ofrecer lo necesario para cada proyecto.',
    acordion: 'La arquitectura nos provee un sentido de pertenencia y como consecuencia crea una comunidad, es por eso que en el proceso de diseño, nuestro objetivo siempre es buscar una utopía.',
    acordion2:'Los proyectos pueden ser: vivienda, comercio, espacios públicos o la intervención de alguna de las anteriores.',
    textPosition: 'left',
    buttonPosition: 'top'
}

export const ARQUITECTURA_DE_INTERIORES = {
    imageList: [
        ArquitecturaInteriores1,
        ArquitecturaInteriores2,
        ArquitecturaInteriores3,
        ArquitecturaInteriores4
    ],
    title: 'ARQUITECTURA DE INTERIORES',
    text: 'Al diseñar interiores buscamos la continuidad y conexión de los espacios. Queremos que cada espacio genere una sensación positiva, además de que vaya de acuerdo con el uso por el cual fue creado.',
    acordion: 'Nosotros proyectamos la arquitectura de forma completa; entramos en cada espacio para buscar un equilibrio de texturas, iluminación, color y mobiliario. Buscamos que la experiencia del usuario y la interacción que tenga con el espacio sea única.',
    acordion2:'',
    textPosition: 'right',
    buttonPosition: 'top'
}

export const CONSTRUCCION = {
    imageList: [
        Construccion1,
        Construccion2,
        Construccion3,
        Construccion4
    ],
    title: 'CONSTRUCCIÓN',
    text: 'La materialización de la arquitectura es un proceso muy importante para nosotros, ya que trabajar con nuestros clientes y llevar su visión a la realidad es un parte muy importante y gratificante de ser arquitectos.',
    acordion: 'Nuestro seguimiento con cliente durante esta etapa es de principio a fin con el objetivo de alcanzar todas las expectativas de los clientes.',
    acordion2:'Nuestro propósito es crear arquitectura consciente.',
    textPosition: 'left',
    buttonPosition: 'bottom'
}

export const NUESTROS_PROYECTOS = {
    proyectos: [
        {
            title: 'VIVIENDA COLECTIVA',
            text: 'El proyecto se encuentra en un  edificio de vivienda en la colonia Condesa. Éste consiste en plantear un edificio de madera con estructura de acero; el foco central es el juego de los espacios interiores y exteriores, que incluyen dobles alturas, juegos de nivel y balcones.',
            text2:'Estos departamentos se diseñaron para tener espacios abiertos y darles una mayor continuidad al interior. Se utilizaron diferentes elementos como ventanas grandes para crear una conexión con el exterior.',
            text3:'',
            text4:'',
            text5:'',
            imageList: [
                ViviendaColectiva1,
                ViviendaColectiva2,
                ViviendaColectiva3,
                ViviendaColectiva4,
                ViviendaColectiva5,
                ViviendaColectiva6,
            ]
        },
        {
            title: 'OFICINAS D2',
            text: 'Proyecto ubicado en un edificio de departamentos en la colonia Roma Norte, el cual es utilizado como oficinas y con acabados y mobiliario muy antiguos. Los dueños buscaban actualizar el espacio, y obtener como resultado algo moderno y más limpio. Sin tirar todo, con nuevos acabados logramos que el uso de los espacios interiores funcionaran de acuerdo a lo que el cliente necesita.',
            text2: 'Siempre la intención es que la función venga de la mano con la estética, y que si el espacio es pequeño tenga la sensación de amplitud.',
            text3:'',
            text4:'',
            text5:'',
            imageList: [
                Oficinas1,
                Oficinas2,
                Oficinas3,
                Oficinas4,
                Oficinas5,
                Oficinas6,
            ]
        },
        {
            title: 'MERCADO ASIÁTICO',
            text: 'El proyecto se encuentra ubicado en el Barrio Chino de la Ciudad de México, en la calle peatonal de Dolores.',
            text2:'El edificio tiene una fachada catalogada, por lo cual no se puede modificar el exterior. En el interior, antiguamente tenía 3 niveles, los cuales se derrumbaron. El proyecto consistía en reestructurar el edificio por medio de vigas y trabes de acero para estabilizar el daño y agregar 4 niveles más; 2 de los cuales abarcan el mercado contemporánea Asiático.',
            text3:'El mercado tiene un estilo moderno que incluye detalles tradicionales asiáticos, como patrones chinos, tonalidades rojas, doradas y amarillas, y materiales clásicos como la madera.',
            text4:'COMING SOON',
            text5:'',
            imageList: [
                //General1,
                //General2,
                //General3,
                //General4,
                //General5,
                //General6,
                //General7,
                //General8,
                //General9,
                //General10,
            ]
        },
        {
            title: 'CASA FLAMENCOS',
            text: 'Esta casa tiene una antigüedad aproximada de 40 años. En el transcurso de los años tuvo 3 intervenciones de diferentes firmas de arquitectos. TR Studio retomó el proyecto, el cual consistió de crear espacios abiertos, modificar las fachadas con un estilo contemporáneo, darle ventilación e iluminación natural.',
            text2:'La casa cuenta con 3 habitaciones, sala, comedor, sala de T.V., cocina, área de lavado, cuarto de servicio, 2 medios baños, 4 baños completos, y un sótano. Nosotros realizamos una remodelación al sótano para poder crear un jardín con un deck. También se redujeron las habitaciones y espacios de la planta baja, se aumentó el espacio en las habitaciones y baños, y agregamos iluminación.',
            text3:'Adicional realizamos una propuesta de interiorismo.',
            text4:'',
            text5:'',
            imageList: [
                Flamencos1,
                Flamencos2,
                Flamencos3,
                Flamencos4,
                Flamencos5,
                Flamencos6,
                Flamencos7,
                Flamencos8,
            ]
        },
        {
            title: 'CASA M.',
            text: 'Casa M. es un proyecto habitacional diseñado para una pareja, la cual buscaba un estilo contemporáneo escandinavo, los materiales como acero, madera y mármol son los que se tomaron para definir la paleta de acabados, así como los colores en el interior y exterior de la casa.',
            text2: 'El proyecto está ubicado en Culiacán, Sinaloa dentro de una privada con restricciones tales como techos inclinados con teja, los cuales nos representaron un reto para incorporar el estilo que ellos estaban buscando, pero no fue imposible.',
            text3: 'Uno de los elementos de diseño importante para nuestros clientes fue tener en planta baja áreas comunes como cocina, sala de estar y comedor sin muros divisorios entre ellas, en otras palabras, planta libre. Además de esto, la casa cuenta con un sótano para estacionamiento de cuatro vehículos, una cava de vinos con temperatura controlada a la cual se puede acceder por el interior de la casa y áreas de servicio aisladas detrás de la cocina.',
            text4: 'En planta alta, se encuentran dos recámaras con su baño y vestidor cada una, la primera tiene balcón con vista a la calle, y la otra recamara cuenta con ventanas que dan vista hacia la doble altura de la casa, rematando hacia el jardín.',
            text5: 'La casa se diseñó de inicio a fin, desde cada espacio y su función hasta los acabados, actualmente está en proceso de construcción.',
            imageList: [
                CasaMaria1,
                CasaMaria2,
                CasaMaria3,
                CasaMaria4,
                CasaMaria5,
                CasaMaria6,
                CasaMaria7,
                CasaMaria8,
                CasaMaria9,
                CasaMaria10,
                CasaMaria11,
                CasaMaria12,
                CasaMaria13,
                CasaMaria14,
                CasaMaria15,
                CasaMaria16,
                CasaMaria17,
            ]
        }
    ]
}
