

import React, { useState } from 'react'; 
import { Carousel, CarouselItem,  CarouselIndicators, CarouselCaption } from 'reactstrap';
import ArrowBack from '../../images/assets/left-arrow.svg';
import ArrowForward from '../../images/assets/right-arrow.svg';

const ImageDoubleGallery = ({ imageList }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const items = imageList;
   

const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
       
        setActiveIndex(nextIndex);
}

const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(activeIndex);
}

const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
}
 if (!items.length) {
        return <div></div>
    }
function imagen(item,index){
    return item[index];
}
function netximagen(item,index){

    if(index==item.length-1){
     return item[0];
    }else
    return  item[index+1];
}
 const slides = items.map((item, index) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                //key={item.id}
                >
                    <div className="double-trans-main">
                             <img src={imagen(items,index)} />
                    </div>
            </CarouselItem>
        );
    });
const slides2 = items.map((item, index) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                //key={item.id}
                >
                   <div className="double-trans-ant">
                
                <img src={netximagen(items,index)}  />
                </div>
            </CarouselItem>
        );
    });
return (
    <div className="image-double-gallery position-relative">
    <div className="ejemplo2">
      <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
             pause='false'     // *********
            ride='carousel'
            >
            <CarouselIndicators 
                
                items={items} 
                activeIndex={activeIndex}
                onClickHandler={goToIndex} />
            {slides2}
        </Carousel>
    </div>
     <div className="ejemplo">
      <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
             pause='false'     // *********
            ride='carousel'
            >
            <CarouselIndicators 
                
                items={items} 
                activeIndex={activeIndex}
                onClickHandler={goToIndex} />
            {slides}
        </Carousel>
    </div>
        <div className="image-double-gallery__button-group mt-lg-5 pt-lg-3">
                <button className="image-double-gallery__button" onClick={previous}>
                    <img src={ArrowBack} alt="back" />
                </button>
                <div className="d-flex align-items-center image-double-gallery__button-indicator px-2">
                    <span className="">
                       {activeIndex+1}
                    </span>
                    <span className="px-2">
                        /
                    </span>
                    <span className="">
                       {items.length}
                    </span>
                </div>
                
                <button className="image-double-gallery__button" onClick={next}>
                    <img src={ArrowForward} alt="forward" />
                </button>
            </div>
      </div>
    );





}


export default ImageDoubleGallery




































